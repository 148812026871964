import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import img001 from '../images/IMG_001.jpg'
import img002 from '../images/IMG_002.jpg'
import img003 from '../images/IMG_003.jpg'
import img004 from '../images/IMG_004.jpg'
import img005 from '../images/IMG_005.jpg'
import img007 from '../images/IMG_007.jpg'
import img008 from '../images/IMG_008.jpg'

const SecondPage = () => (
  <Layout>
    <SEO title="What's required to have SIMTRIM work for you" />
    <h1>What is Required to Have SIMTRIM Work For You?</h1>
    <div className="video-container">
      <iframe title="install-video" src="https://www.youtube.com/embed/OUhkHbJGbis?rel=0&modestbranding=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>    </div>
    <p>With the SIMTRIM™ Molding System, the molding and trim is cut to fit in exactly the same way that it is cut for conventional molding (CM) installations. CM systems require the use of typically a brad nailer or finishing nails (and a hammer & punch) to attach the molding or trim to the wall. With the SIMTRIM™ Molding System all you really need is a Phillips or Robertson manual screw driver or power drill with appropriate bits. When installing CM or trim, the trim is typically cut to fit, pre-finished, then after all nailing is done, a touch up carpenter sets in the nails so they can be puttied or filled and then those blemishes are refinished. When installing trim with the SIMTRIM™ System the trim is cut to fit as it is with the CM systems and then the trim is completely finished before it is installed. The installation procedure then involves attaching the female snap components in the back of the trim and attaching the male snap components to the wall (to match the positions of the female snap components). After the trim is snapped to the wall there is no need for finishing carpenters to return because the trim is securely attached to the wall and it is completely removable and re-installable.</p>
    <p>To make the Simtrim™ Molding System even easier to use the whole system will be sold with easy to follow installation instructions and the required and recommended list of tools will be included in the instructions.</p>
    <p>The attached video basically indicates all of the basic steps of installing molding or trim with the SIMTRIM™ System.</p>
    <p>The basic Female and Male Simtrim™ Molding System snap components are currently available and can be ordered from Simtrim™ Molding Systems Ltd.</p>
    <p>At present the required dovetail groove in the back face of molding will have to be installed by the consumer, but as the consumer will find out, the groove is very easy to install especially when using proper equipment.</p>
    <p>Simtrim™ Molding Systems Ltd. has a number of recommended tools and equipment packages that will make it quite easy for nearly everyone with a router and router table to install their own dovetail groove.</p>
    <p>The following is a list of required and recommended components and equipment to allow nearly anyone to install the Simtrim™ Molding System (Note: it is expected that the consumer will need to provide their own router and router table in order to be able to make the required dovetail groove):</p>
    <span className="footnote">**Prices are given in Canadian Dollars</span>
    <br />
    <span className="footnote">***GST and shipping costs are not included in prices given below</span>
    <ol style={{ marginTop: '1rem' }}>
      <li>
        <p>Required – Female and Male snap components will typically be sold in packages of 50 each and will be sold for $9.00Cdn/package and $6.00Cdn/package respectively. For an estimate of how many snaps are recommended for a typical installation, an average of one male/female snap combination is normally installed for about every foot or 30cm of molding length. A typical 10’ x 10’ (3m x 3m) bedroom with one man door, one closet door and one standard sized window will require approximately 100 snap components. This would equate to about $30.00Cdn for the male and female snap components for this example bedroom.</p>
        <div className="img-container"><img src={img001} alt="simtrim screws" /><img src={img002} alt="snap connectors" /></div>
      </li>
      <li>
        <p>Required – Dovetail Router bit, ¾”, 14 degree wing and carbide tipped (both ¼” and ½” shanks available), suggested retail price $25.00Cdn. This ¾” bit is the current or initial standard size of the dovetail groove required to make the Simtrim™ Molding System work. Note that this item is currently not in stock at this time.</p>
        <div className='img-container'>
          <img src={img003} alt="router bit" />

        </div>
      </li>
      <li>
        <p>Recommended – Router table fence hold down device.  The router and hold down device shown in the attached short video is an example of an extremely simple and effective hold down device.  Simtrim™ Molding Systems Ltd. would be happy to be able to sell components for generic hold down devices to potential customers.  Each router and router table combination will be different from others, so the type of hold down device for each table will vary as well.  If anyone is interested in receiving more information on the creation of the dovetail groove please let us know.  Basically the general rule of thumb is to install the 3/4″ with 14 degree wing dovetail groove with a center of groove distance 3/4″ from the widest edge of molding or trim and at a depth of 3/16″.  If the trim you are installing is a baseboard and it has an overall height of more than approximately 3 3/4″ to 4″, it is recommended that a second groove be cut into the back face of the trim.  The position of this second groove will be dependent on how thick the baseboard is near the top edge of the board.  In most cases this distance may also be approximately 3/4″ from the center of the groove to the top edge of the board.  (Note that each sample of molding or trim is different from others in terms of material, thickness and side profile, so each groove may be tailored for that specific molding type.) Simtrim™ Molding Systems Ltd. highly recommends a type of manually constructed fence and hold down device as shown in the attached video and we would be happy to source parts for this type of hold down for potential customers.</p>
        <div className="img-container">
          <img src={img004} alt="router example" />
          <img src={img005} alt="router example" />
        </div>
      </li>
      <li>
        <p>Recommended – Angle Driver attachment for speed drill or power screw driver. To install the Simtrim™ Molding System on baseboards, it is highly recommended to use a 90 degree angle speed drill driver attachment. An angle driver is one of only a few ways that screws can be power driven with small clearances between the floor and the desired screw locations. Normal power drills or screw drivers are not capable of driving in screws completely perpendicular to the wall if the screw location is too close to the floor. We also recommend that a 1/8″ diameter pilot hole be drilled for each male snap screw and this is to make sure that the screw is attached as close to perpendicular to the wall as is possible.  For the angle drill attachment a quick attach hexagonal 1/8″ diameter bit is recommended.  Suggested retail price of the angle driver is $25.00Cdn. There are also specific offset drivers available from some retail stores, but they can get quite expensive to purchase. If you decide to install many of the male snap components for baseboard installations the purchase of an offset driver may be a good alternative.</p>
        <div className="img-container"><img src={img007} alt="angle driver"/></div>
      </li>
      <li>
        <p>Recommended – Female Snap Locator Tacks. The snap locator tacks are designed to mark exact locations of the male snap components and their use will significantly speed up the installation of the Simtrim™ Molding System. Packages of 20 locator tacks are available from Simtrim™ and the suggested retail of these packages are $3.00Cdn/package. Note that snap locator tacks can be re-used multiple times, so it is expected that no more than 20 locator tacks would normally be used at one time to mark the male snap locations. One package of locator tacks will be sufficient to handle nearly any size of job.</p>
        <div className="img-container"><img src={img008} alt="locator tacks"/></div>
      </li>
    </ol>
  </Layout>
)



export default SecondPage
